import React from 'react'
import preset from '@rebass/preset'
// import {ThemeProvider} from 'styled-components'
import {Button, Text} from 'rebass/styled-components'
import ThemeProvider from "../components/ThemeProvider";
// const PROVIDER = (props) =>
//   <ThemeProvider theme={preset}>
//     {props.children}
//   </ThemeProvider>

export default () => <ThemeProvider
  >
  <Button variant="primary">Beep</Button>
  <Text>RRRRLO</Text>
</ThemeProvider>


const themeFixed = {
  colors: {
    background: 'black',
    primary: 'tomato',
  },
  space: [ 0, 6, 12, 24, 48 ],
  fontSizes: [ 14, 16, 18, 20, 24 ],
  radii: {
    default: 12,
  }
}
